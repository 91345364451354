import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Про оренду яхт Petadazen
			</title>
			<meta name={"description"} content={"Petadazen - прокладіть свій курс, відчуйте надзвичайне"} />
			<meta property={"og:title"} content={"Про нас | Про оренду яхт Petadazen"} />
			<meta property={"og:description"} content={"Petadazen - прокладіть свій курс, відчуйте надзвичайне"} />
			<meta property={"og:image"} content={"https://ua.petadazen.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.petadazen.com/img/4523525325.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.petadazen.com/img/4523525325.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://ua.petadazen.com/img/4.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="40px 0px 0px 50px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Наша суть
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Про Нас
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Розташований уздовж спокійних берегів, Petadazen виходить за рамки звичайної оренди яхт. Це більше, ніж послуга, це втілення прихильності до розкоші, бездоганно поєднуючи морську елегантність зі свободою відкритих морів. Petadazen - це не просто оренда яхти, це можливість відправитися в подорож, де розкіш зустрічається з хвилеподібним ритмом океану.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="30px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Серцебиття Petadazen
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Зародження компанії Petadazen пов'язане зі спільною пристрастю до моря та непохитним прагненням переосмислити морські враження. Наша суть обертається навколо ідеї, що кожна подорож - це можливість створити незабутні спогади, де серцебиття Petadazen резонує з ніжним шумом хвиль.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Здійсніть свою морську одіссею
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Пориньте в атмосферу Petadazen, де погойдування щогл, блакитні води та ніжний гул яхти стануть тлом для створення вашої морської одіссеї. Petadazen - це не просто оренда, це запрошення створювати надзвичайні морські казки.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Чому ми?
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								- Морська експертиза: Спираючись на багатий арсенал морських знань, Petadazen пропонує не просто яхти, а глибоке розуміння морів, що гарантує безпечну та захоплюючу подорож.
							</Text>
						</Box>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 35px 0px">
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								- Елегантність у кожній деталі: Від ретельного проектування наших яхт до кураторських послуг на борту.
								<br />
								<br />
								- Капітани комфорту: Кожна мить, проведена на борту яхти Petadazen, буде сповнена абсолютного комфорту і безтурботності.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});